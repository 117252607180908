<template>
  <v-dialog :value="load"
            @keydown.esc="toggleRecoveryForm"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            :max-width="($vuetify.breakpoint.sm ? '80%' :
              ($vuetify.breakpoint.md ? '60%' : '40%'))"
            transition="scale-transition" persistent>
    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        <span class="headline">{{ $t('password_recovery.form__header') }}</span>

        <v-spacer></v-spacer>

        <v-btn @click="toggleRecoveryForm"
               icon>
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form @submit.prevent="sendEmail" ref="form">
          <v-layout row wrap justify-center>
            <v-flex xs12 sm11 md10 mb-4>
              <v-alert v-model="alert.display"
                       :type="alert.type"
                       dismissible>
                {{ alert.text }}
              </v-alert>
            </v-flex>
            <v-flex xs12 sm11 md10>
              <v-text-field v-model="email"
                            :label="$t('common.field__email-header')"
                            prepend-inner-icon="email"
                            :rules="[v =>!!v || $t('common.field__text-validation-error'),
                                     v => email_pattern.test(v) || $t('common.field__email-validation-error')]"
                            clearable
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="sendEmail" :loading="processing"
                   :disabled="!email_pattern.test(email)"
                   color="blue" block dark text>
              {{ $t('common.button__send') }}
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "@/plugins/axios";

export default {
  name: 'PasswordRecovery',
  props: {
    load: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    visible: false,
    processing: false,
    email: '',
    // Object representing an alert into the recovery form.
    alert: {
      display: false,
      type: 'info',
      text: '',
    },
    // Pattern to recognize a valid email address.
    // eslint-disable-next-line no-useless-escape
    email_pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  }),
  methods: {
    /**
     * Sends the user email in order to recover password
     */
    sendEmail() {
      this.processing = true;
      axios.get(`${axios.defaults.baseURL}/recover`,
          JSON.stringify({
            email: this.email,
            url: axios.defaults.baseURL
          })).then(() => {
        this.processing = false;
        this.$refs.form.reset();
        this.alert.type = 'success';
        this.alert.text = this.$t('register.alert__success');
        this.alert.display = true;
      }).catch(() => {
        this.processing = false;
        this.alert.type = 'error';
        this.alert.text = this.$t('register.alert__error');
        this.alert.display = true;
      });
    },
    /**
     * Emits event to close the recovery form.
     */
    toggleRecoveryForm() {
      this.alert.display = false;
      this.email = '';
      this.$store.dispatch('setDialogStatus', {
        name: 'password_recover',
        value: false
      })
    }
  }
};
</script>

<style scoped>

</style>
